import React, {useCallback, useEffect, useState} from 'react';
import './App.scss';
import 'react-dropzone-uploader/dist/styles.css'
import LoadingScreen from "./more/LoadingScreen";
import {IFileWithMeta, useUploading} from "./more/useUploading";

import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import {ThankYouScreen} from "./more/ThankYouScreen";
import {WelcomeScreen} from "./more/WelcomeScreen";
import {NoCodeScreen} from "./more/NoCodeScreen";
import {SmallHeader} from "./more/SmallHeader";
import {GradientProgressBar} from "./more/GradientProgressBar";
import {GradientButton} from "./more/GradientButton";
import {useQrMemoryApi} from "./more/useQrMemoryApi";
import { clarity } from 'react-microsoft-clarity';
import {useFreeVersionLimitExceededModal} from "./more/FreeVersionLimitExceededModal";
import {useNotYetActivatedModal} from "./more/NotYetActivatedModal";
import {useUploadPeriodOverModal} from "./more/UploadPeriodOverModal";


export interface GuestPageCfg {
    headerImageUrl?: string;
    headerImageUrlMobile?: string;
    title: string;
    titleSize: string,
    titleFont: string,
    subTitle?: string,
    subTitleSize: string,
    subTitleFont: string,
    imageToTitleStyle: string
}

export interface QrMemoriesContextDto {
    cfg: GuestPageCfg;
}

export type HeaderTransitionType = 'gradient';

function App() {
    clarity.init('kiiu5ushh5');

    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const [loading, setLoading] = useState(true);
    const [codeInvalid, setCodeInvalid] = useState(false);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [context, setContext] = useState<QrMemoriesContextDto | null>(null);
    const [contextLoading, setContextLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [detailsOpenedFor, setDetailsOpenedFor] = useState<IFileWithMeta | null>(null);

    const [succeeded, setSucceeded] = useState(false);


    const freeVersionLimitModal = useFreeVersionLimitExceededModal();
    const notYetActivatedModal = useNotYetActivatedModal();
    const uploadPeriodOverModal = useUploadPeriodOverModal();

    const { exchangeCodeForContext, cancelMedia, confirmMedia } = useQrMemoryApi();
    const { getRootProps, getInputProps, filesInfo, allProcessed, alreadyProcessed, cancelUpload, confirmUpload } = useUploading(code,
        name, cancelMedia, confirmMedia, setSucceeded, freeVersionLimitModal.openModal, notYetActivatedModal.openModal, uploadPeriodOverModal.openModal);

    const [inSheetLoading, setInSheetLoading] = useState(false);


    const cancelUploadLocal = useCallback(async (key: string) => {
        setInSheetLoading(true);
        await cancelUpload(key);
        setDetailsOpenedFor(null);
        setInSheetLoading(false);
    }, [cancelUpload]);

    const confirmUploadLocal = useCallback(async () => {
        setSending(true);
        try {
            await confirmUpload(message);
            setSucceeded(true);
            setMessage('')
        } finally {
            setSending(false);
        }
    }, [confirmUpload, message])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setCode(params.get('code')!);
    }, []);

    useEffect( () => {
        if (code && !contextLoading && !context) {
            setContextLoading(true);

            exchangeCodeForContext(code).then((context) => {
                setContext(context);
                setLoading(false);
                setContextLoading(false);
            }).catch(e => {
                console.log(e);
                if (e?.response?.data?.status === 400 || e?.response?.data?.status === 404) {
                    setCodeInvalid(true);
                }
            });
        }
    }, [code, contextLoading]);

    if (!code || codeInvalid) {
        return <NoCodeScreen inCode={codeInvalid ? code : undefined} codeInvalid={codeInvalid} />;
    }

    if (loading || !context) {
        return <LoadingScreen />;
    }


    if (succeeded) {
        return <ThankYouScreen isMobile={isMobile} context={context} getInputProps={getInputProps} getRootProps={getRootProps}/>
    }

    if (!filesInfo.length) {
        return <>
            { freeVersionLimitModal.ModalTemplate }
            { notYetActivatedModal.ModalTemplate }
            { uploadPeriodOverModal.ModalTemplate }
            <WelcomeScreen code={code} isMobile={isMobile} context={context} getInputProps={getInputProps} getRootProps={getRootProps} onNameUpdated={name => setName(name)} />
            </>;
    }

    return (
        <div className="min-h-screen flex flex-col max-w-screen-sm mx-auto">
            { freeVersionLimitModal.ModalTemplate }
            { notYetActivatedModal.ModalTemplate }
            { uploadPeriodOverModal.ModalTemplate }
            <SmallHeader context={context}>
                {!allProcessed && !sending && <p className='mt-4 mb-2'>Etap: <span className='font-semibold'>Przygotowywanie pamiątki</span></p>}
                {allProcessed && !sending && <p className='mt-4 mb-2'>Etap: <span className='font-semibold'>Pamiątka gotowa do wysłania</span></p>}
                {sending && <p className='mt-4 mb-2'>Etap: <span className='font-semibold'>Wysyłanie pamiątki</span></p>}
                <div className='flex flex-row gap-2 mt-1 w-full'>
                    <GradientProgressBar className={'w-1/5'} value={100} max={100} />
                    <GradientProgressBar className={'flex-grow'} value={alreadyProcessed} max={filesInfo.length} />
                    {/*<progress className="progress progress-success w-1/5" value="100" max="100"></progress>*/}
                    {/*<progress className="progress progress-success flex-grow" value={alreadyProcessed} max={filesInfo.length}></progress>*/}
                    {/*<div className='bg-gray-200 rounded-md w-1/5 h-2'></div>*/}
                    {/*<div className='bg-gray-200 rounded-md flex-grow h-2'></div>*/}
                </div>
            </SmallHeader>

            <div className="flex flex-col items-center justify-between min-h-full p-4">

                {sending && <div className='absolute left-0 right-0 top-[189px] bottom-0 flex flex-col items-center justify-center z-[100] bg-white/50'>
                    <img className="animate-spin-slow" width={80} height={80} src={"assets/spinner.png"} />
                    {/*<h1 className='text-xl text-amber-00 font-light'>Wysyłanie pamiątki</h1>*/}
                </div>}

                {/*{filesInfo.length ? <div>*/}
                {/*    /!*<h2 className='text-lg font-light'>Przejrzyj </h2>*!/*/}
                {/*    /!*<h2>Etap: Przegląd</h2>*!/*/}
                {/*    <p className='font-light text-gray-800'>Przejrzyj multimedia wybrane do wgrania. Jeśli wybrałeś coś przez przypadek, kliknij na odpowiednie zdjęcie/film i potwierdź usunięcie.</p>*/}
                {/*</div> : null}*/}

                <div className={'w-full'}>
                    {/*<h2 className='text-lg font-light'>Przejrzyj </h2>*/}
                    {/*<h2>Etap: Przegląd</h2>*/}
                    {!allProcessed && <p className='font-light text-gray-800'>Trwa wgrywanie multimediów, prosimy o chwilę cierpliwości. Jeśli chcesz, możesz w tym czasie dodać życzenia lub inną wiadomość.</p>}
                    {allProcessed && <p className='font-light text-gray-800'>Personalizowana wiadomość</p>}


                    <textarea
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        className="textarea textarea-bordered w-full mt-2 font-light" placeholder="Twoja wiadomość..."></textarea>
                </div>

                <div className="w-full mt-4">
                    <div className="grid grid-cols-3 md:grid-cols-5 gap-2 mt-2">
                        {filesInfo.map((file) => (
                            // (file.meta.status === 'preparing' || file.meta.percent === 100 || file.meta.thumbUrl ? '' : 'opacity-40 ') +
                            <div onClick={() => file.meta.key && setDetailsOpenedFor(file)} key={file.meta.id} className={'bg-gray-200 rounded-md aspect-square cursor-pointer overflow-hidden relative ng-star-inserted flex flex-col items-center justify-between mb-2 p-2 border'}>
                                <div className="flex flex-row items-center justify-between rounded w-full absolute left-0 top-0 right-0 bottom-0">
                                    {!file.meta.thumbUrl && file.meta.type.startsWith('video/') && <i className='fi fi-rr-film block w-full text-center text-xl text-gray-400' />}
                                    {!file.meta.thumbUrl && file.meta.type.startsWith('image/') && <i className='fi fi-rr-picture block w-full text-center text-xl text-gray-400' />}
                                    {file.meta.thumbUrl && <img src={file.meta.thumbUrl as string} alt={file.meta.name} className="h-full w-full object-cover c-hide"/>}
                                </div>
                                {file.meta.thumbUrl && file.meta.type.startsWith('video/') && <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none "><i className="fi fi-rr-play pointer-events-none text-white" style={{fontSize: 20}}></i></div>}

                                {/*{(file.meta.percent !== 100 || file.meta.thumbUrl) && <progress className="absolute bottom-0 progress progress-success w-full" value={file.meta.thumbUrl ? 100 : file.meta.percent} max="100"></progress>}*/}
                                {/*{!(file.meta.percent !== 100 || file.meta.thumbUrl) && <progress className="absolute bottom-0 progress progress-success w-full"></progress>}*/}

                                {(file.meta.percent !== 100 || file.meta.thumbUrl) && <div className={'absolute bottom-0 w-full'}><GradientProgressBar className={'w-full'} value={file.meta.thumbUrl ? 100 : file.meta.percent} max={100} /></div> }
                                {!(file.meta.percent !== 100 || file.meta.thumbUrl) && <div className={'absolute bottom-0 w-full'}><GradientProgressBar className={'w-full'} max={100} /></div>}


                                {/*<button onClick={() => handleDelete(file)} className="btn btn-error">*/}
                                {/*    <i className="fas fa-trash"></i>*/}
                                {/*</button>*/}
                            </div>
                        ))}

                        <div {...getRootProps({className: 'dropzone bg-gray-100 rounded-md aspect-square cursor-pointer overflow-hidden relative ng-star-inserted flex flex-col items-center justify-between mb-2 p-2 border'})}>
                            {/*<input {...getInputProps()} />*/}
                            <input {...getInputProps({className: 'btn btn-primary w-full'})} />
                            <div className="flex flex-col items-center justify-center rounded w-full absolute left-0 top-0 right-0 bottom-0">
                                <p className='opacity-60 text- font-light'>Wgraj więcej</p>
                                <i className='fi fi-sr-camera block w-full text-center opacity-60' />
                            </div>
                            {/*<p>Drag 'n' drop some files here, or click to select files</p>*/}
                        </div>
                    </div>
                </div>

                <div className='bg-white/95 w-full fixed bottom-0 p-4 pb-0 max-w-screen-sm mx-auto'>
                    {/*<button onClick={() => setSucceeded(true)} disabled={!allProcessed} className={(allProcessed ? 'border-amber-200 text-amber-600 bg-gradient-to-r from-amber-200 to-orange-200 ' : 'border-gray-200 text-gray-600 bg-gradient-to-r from-gray-200 to-gray-200 ') +  "btn justify-center   w-full flex items-center"}>Wyślij pamiątkę<i className='fi fi-sr-hand-holding-heart mt-0.5'/></button>*/}
                    <GradientButton onClick={() => confirmUploadLocal()} disabled={!allProcessed} label='Wyślij pamiątkę' icon='fi-sr-hand-holding-heart' />

                    <div className='text-xs items-center text-center justify-center p-3 flex flex-row gap-1 '>
                        <p className='text-gray-600'>Obsługiwane przez</p> <img className='h-[30px]' src={'https://cdn.eventioo.com/public/static/logo-email.png'} />
                    </div>
                </div>

                <div className='h-[118px]'></div>

                <BottomSheet open={!!detailsOpenedFor} onDismiss={() => setDetailsOpenedFor(null)}>
                    <div className={'p-4 flex flex-col gap-6 relative'}>
                        {inSheetLoading && <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
                            {/*<div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"></div>*/}
                            <img className="animate-spin-slow" width={60} height={60} src={"assets/spinner.png"} />

                        </div>}
                        {inSheetLoading && <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center bg-white opacity-80">
                        </div>}

                        <div className={'flex flex-col gap-2'}>
                            { detailsOpenedFor?.meta.type.startsWith('image/') && <h2 className="text-lg font-light">Zdjęcie</h2>}
                            { detailsOpenedFor?.meta.type.startsWith('video/') && <h2 className="text-lg font-light">Film</h2>}

                            <div>
                                {/* TODO - use webUrl!! */}

                                { detailsOpenedFor?.meta.type.startsWith('image/') && <img src={detailsOpenedFor?.meta.webUrl || detailsOpenedFor?.meta.thumbUrl} />}
                                { detailsOpenedFor?.meta.type.startsWith('video/') && <video controls={true} src={detailsOpenedFor?.meta.webUrl} />}

                            </div>
                            <p className="font-light text-sm"><span className='text-gray-500'>Nazwa:</span> {detailsOpenedFor?.meta?.name}</p>
                            {/* TODO Better render size */}
                            <p className="font-light text-sm"><span className='text-gray-500'>Rozmiar</span> {detailsOpenedFor?.meta?.size} B</p>
                        </div>

                        {/*<p className="font-light text-sm"><span className='opacity-60'>Rozmiar</span> 3.43 MB</p>*/}
                        <button onClick={() => cancelUploadLocal(detailsOpenedFor?.meta.key!)} className={'btn btn-danger justify-center   w-full flex items-center'}>Usuń {detailsOpenedFor?.meta.type.startsWith('image/') ? 'zdjęcie' : 'film'}<i className={'fi  mt-0.5 fi-rr-trash'}/></button>
                    </div>

                </BottomSheet>
            </div>
        </div>
    );

  // return code ? (
  //   <div className="App">
  //     <p>Wesele Krzysia i Zosi</p>
  //     <ImageAudioVideo code={code} />
  //   </div>
  // ) : <>Missing parameter!</> ;
}

export default App;
