import React, {useCallback} from 'react';

export const useFreeVersionLimitExceededModal = () => {

    const openModal = useCallback(() => {
        (document.getElementById('free_limit_exceded_modal') as any)?.showModal()
    }, []);

    const ModalTemplate = <dialog id="free_limit_exceded_modal" className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
            <h3 className="font-bold text-lg flex items-center gap-2"><i className='fi fi-rr-rocket-lunch mt-[5px]'></i> <span>Limit wykorzystany</span></h3>
            <p className="py-4">Darmowy limit wgrywania zdjęć i filmów w wersji próbnej został wykorzystany.</p>
            <p className="">Jeśli usługa spełnia Twoje oczekiwania - zapraszamy do jej wykupienia w panelu administracyjnym.</p>
            <p className="pt-4">W razie dodatkowych pytań serdecznie zapraszamy do kontaktu pod adresem e-mail <a href= "mailto: kontakt@eventioo.com">kontakt@eventioo.com</a></p>
            <div className="modal-action">
                <form method="dialog">
                    {/* if there is a button in form, it will close the modal */}
                    <button className="btn">Zamknij</button>
                </form>
            </div>
        </div>
    </dialog>;

    return {
        openModal,
        ModalTemplate,
    }
};
