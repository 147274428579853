import React from 'react';

export const DemoDisclaimer: React.FC = () => {

    const [dismissed, setDismissed] = React.useState(false);

    if (dismissed) {
        return <></>
    };

    return (<div className='flex relative flex-row justify-center bg-gradient-to-r  gap-4 from-green-600 to-green-700 text-white p-4'>
        <div className='flex flex-col gap-2'>
            <strong className='flex flex-row items-center'><i className='fi fi-sr-shield mr-1 mt-0.5'></i><p>Wersja demonstracyjna</p></strong>
            <p className='text-gray-100'>Wybrane przez Ciebie pliki nie opuszczą Twojego urządzenia.</p>
        </div>
        <div>
            <button onClick={() => setDismissed(true)} className="absolute top-1 right-1 btn btn-square text-white btn-ghost">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    </div>);

};

