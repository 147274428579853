import React, {useEffect, useState} from 'react';
import {Header2} from "./Header2";
import {BottomSheet} from "react-spring-bottom-sheet";

interface GradientButtonProps {
    label: string;
    icon?: string;
    disabled?: boolean;
    onClick?: () => any;
    className?: string;
}

export const GradientButton: React.FC<GradientButtonProps> = ({ label, icon, disabled, onClick, className }: GradientButtonProps) => {

    const [name, setName] = useState<string>('');
    const [consentGiven, setConsentGiven] = useState<boolean>(false);
    const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    useEffect(() => {
        setIsFormValid(!!(name.length && consentGiven));
    }, [name, consentGiven])

    return (
        <button onClick={() => onClick ? onClick() : null } disabled={disabled} className={className + ' ' + (!disabled ? 'border-amber-200 text-amber-600 bg-gradient-to-r from-amber-200 to-orange-200 ' : 'border-gray-200 text-gray-600 bg-gradient-to-r from-gray-200 to-gray-200 ') +  "btn justify-center   w-full flex items-center"}>{label}<i className={'fi  mt-0.5 ' + icon}/></button>
    );
};


