import React, {useCallback} from 'react';

export const useUploadPeriodOverModal = () => {

    const openModal = useCallback(() => {
        (document.getElementById('upload_period_over_modal') as any)?.showModal()
    }, []);

    const ModalTemplate = <dialog id="upload_period_over_modal" className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
            <h3 className="font-bold text-lg flex items-center gap-2"><i className='fi fi-rr-sad mt-[5px]'></i> <span>QR Pamiątka nieaktywna</span></h3>
            <p className="py-4">Okres przeznaczony na wgrywanie pamiątek niestety już minął. Zachęcamy do przekazania multimediów bezpośrednio do organizatorów wydarzenia. </p>
            <p className="pt-4">W razie pytań odnośnie usługi zapraszamy do kontaktu pod adresem e-mail <a href= "mailto: kontakt@eventioo.com">kontakt@eventioo.com</a></p>
            <div className="modal-action">
                <form method="dialog">
                    {/* if there is a button in form, it will close the modal */}
                    <button className="btn">Zamknij</button>
                </form>
            </div>
        </div>
    </dialog>;

    return {
        openModal,
        ModalTemplate,
    }
};
