import axios from "axios";
import {useCallback} from "react";

export const useQrMemoryApi = () => {

    const exchangeCodeForContext = useCallback(async (bucketCode: string) => {
        const response = (await axios.post(`${process.env.REACT_APP_API_URL}/memories/code-for-context`, {
            bucketCode
        })).data;

        return response;
    }, []);

    const cancelMedia = useCallback(async (bucketCode: string, key: string) => {
        if (bucketCode === 'demo') {
            return;
        }
        await axios.post(`${process.env.REACT_APP_API_URL}/memories/cancel`, {
            bucketCode,
            key
        });
    }, []);

    const confirmMedia = useCallback(async (bucketCode: string, uploadId: string, message?: string) => {
        if (bucketCode === 'demo') {
            return;
        }
        await axios.post(`${process.env.REACT_APP_API_URL}/memories/send`, {
            message,
            bucketCode,
            uploadId
        });
    }, []);

    return {
        exchangeCodeForContext,
        cancelMedia,
        confirmMedia
    };
}