import React, {ReactNode, useEffect} from 'react';
import {QrMemoriesContextDto} from "../App";

interface SmallHeaderProps {
    children?: ReactNode;
    context: QrMemoriesContextDto;
}

export const SmallHeader: React.FC<SmallHeaderProps> = ({ context, children }: SmallHeaderProps) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<header className=' border-b px-4 pb-4'>
        <div className='pt-8 flex flex-col items-center justify-center '>
            <h1 className={(context.cfg?.titleSize || 'text-5xl') + ' ' + (context?.cfg?.titleFont || 'great-vibes-regular') + " font-light text-center"}>{context?.cfg?.title}</h1>
            { context?.cfg?.subTitle && <h1 className={(context.cfg?.subTitleSize || 'text-3xl') + ' ' + (context?.cfg?.subTitleFont || 'great-vibes-regular') + " font-light text-center"}>{context?.cfg?.subTitle}</h1> }
        </div>
        {children}
    </header>);
};

