import React from 'react';
import {QrMemoriesContextDto} from "../App";
import {BetaDisclaimer} from "./BetaDisclaimer";
import {DemoDisclaimer} from "./DemoDisclaimer";

interface LargeHeaderProps {
    isMobile: boolean;
    context: QrMemoriesContextDto;
    onClick?: () => void;
    betaDisclaimer?: boolean;
    demoDisclaimer?: boolean;
}

export const LargeHeader: React.FC<LargeHeaderProps> = ({ isMobile, context, onClick, betaDisclaimer, demoDisclaimer }: LargeHeaderProps) => {
    // const fallbackBackgroundUrl = 'https://images.pexels.com/photos/1024984/pexels-photo-1024984.jpeg?cs=srgb&dl=pexels-asadphoto-1024984.jpg&fm=jpg';
    const fallbackBackgroundUrl = ''; // TODO
    const cfg = context?.cfg;

    // TODO fallback bg as gradient

    return (<header onClick={() => onClick ? onClick() : null}>
        {betaDisclaimer && <BetaDisclaimer/> }
        {demoDisclaimer && <DemoDisclaimer/> }
        <div className='w-full relative bg-gradient-to-r from-orange-200 to-amber-200 bg-no-repeat h-[35vh] bg-center bg-cover -z-10' style={{
            backgroundPositionY: !isMobile ? '20%' : undefined,
            backgroundImage: cfg?.headerImageUrl ? `url(${isMobile && cfg?.headerImageUrlMobile ? cfg.headerImageUrlMobile : (cfg.headerImageUrl ? cfg.headerImageUrl : fallbackBackgroundUrl)})` : undefined
        }}>
            {cfg?.imageToTitleStyle === 'gradient' && <div className='h-64 absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent to-white dark:to-base-100'></div> }
            {cfg?.imageToTitleStyle === 'rounded-overflow' && <div className='h-4 absolute bottom-0 left-0 right-0 border-b'></div> }

        </div>

        <div className={'p-4 pt-8 flex flex-col items-center justify-center' + (cfg?.imageToTitleStyle === 'rounded-overflow' ? ' rounded-t-[2rem] z-50 bg-white -mt-8 ' : '') }>
            <h1 className={(cfg?.titleSize || 'text-5xl') + ' ' + (cfg?.titleFont || 'great-vibes-regular') + " font-light text-center"}>{cfg?.title}</h1>
            { cfg?.subTitle && <h1 className={(cfg?.subTitleSize || 'text-3xl') + ' ' + (cfg?.subTitleFont || 'great-vibes-regular') +  " font-light text-center"}>{cfg?.subTitle}</h1>}
        </div>

    </header>);
};

