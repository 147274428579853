import React from 'react';

export const BetaDisclaimer: React.FC = () => {

    const [dismissed, setDismissed] = React.useState(false);

    if (dismissed) {
        return <></>
    };

    return (<div className='flex flex-row justify-center bg-gradient-to-r from-red-500 to-red-600 text-white p-4'>
        <div className='flex flex-col gap-2'>
            <strong>Trwają zamknięte testy systemu</strong>
            <p className='text-gray-100'>Pracujemy jeszcze nad aktualizacją polityki prywatności oraz warunków użytkowania. Prosimy o niewrzucanie prywatnych zdjęć i filmów.</p>
        </div>
        <div>
            <button onClick={() => setDismissed(true)} className="btn btn-square text-white btn-outline">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    </div>);

};

