import React, {useCallback, useEffect, useRef, useState} from 'react';
import {DropEvent, useDropzone} from "react-dropzone";
import axios from "axios";

export type StatusValue =
    | 'rejected_file_type'
    | 'rejected_max_files'
    | 'preparing'
    | 'error_file_size'
    | 'error_validation'
    | 'ready'
    | 'started'
    | 'getting_upload_params'
    | 'error_upload_params'
    | 'uploading'
    | 'exception_upload'
    | 'aborted'
    | 'restarted'
    | 'removed'
    | 'error_upload'
    | 'headers_received'
    | 'done';


export interface QrMemoriesMediaDto {
    objectKey?: string;
    thumbUrl?: string;
    webUrl?: string;
    type: string;
    takenAt: string;
    uploadedBy: string;
    uploadedAt: string;
    size?: string;
}

export interface IMeta {
    id: string
    key: string;
    status: StatusValue
    type: string // MIME type, example: `image/*`
    name: string
    uploadedDate: string // ISO string
    percent: number
    size: number // bytes
    lastModifiedDate: string // ISO string
    previewUrl?: string // from URL.createObjectURL
    duration?: number // seconds
    width?: number
    height?: number
    videoWidth?: number
    videoHeight?: number
    validationError?: any
    videoObjectUrl?: string;
    thumbUrl?: string;
    webUrl?: string;
}

export interface IFileWithMeta {
    file: File
    meta: IMeta
    cancel?: () => void
    restart?: () => void
    remove?: () => void
    xhr?: XMLHttpRequest,
    uploadParams?: IUploadParams
}

export interface IUploadParams {
    url: string
    body?: string | FormData | ArrayBuffer | Blob | File | URLSearchParams
    fields?: { [name: string]: string | Blob }
    headers?: { [name: string]: string }
    meta?: { [name: string]: any }
}

const DEMO_FILES = [
    {
        id: 'demo-1',
        type: 'image/jpeg',
        thumbUrl: 'https://cdn.eventioo.com/public/static/qrmemories/1.jpg',
        webUrl: 'https://cdn.eventioo.com/public/static/qrmemories/1.jpg'
    },
    {
        id: 'demo-2',
        type: 'image/jpeg',
        thumbUrl: 'https://cdn.eventioo.com/public/static/qrmemories/2.jpg',
        webUrl: 'https://cdn.eventioo.com/public/static/qrmemories/2.jpg'
    },
    {
        id: 'demo-3',
        type: 'image/jpeg',
        thumbUrl: 'https://cdn.eventioo.com/public/static/qrmemories/3.jpg',
        webUrl: 'https://cdn.eventioo.com/public/static/qrmemories/3.jpg'
    },
    {
        id: 'demo-4',
        type: 'image/jpeg',
        thumbUrl: 'https://cdn.eventioo.com/public/static/qrmemories/4.jpg',
        webUrl: 'https://cdn.eventioo.com/public/static/qrmemories/4.jpg'
    },
    {
        id: 'demo-5',
        type: 'image/jpeg',
        thumbUrl: 'https://cdn.eventioo.com/public/static/qrmemories/5.jpg',
        webUrl: 'https://cdn.eventioo.com/public/static/qrmemories/5.jpg'
    },
    {
        id: 'demo-6',
        type: 'image/jpeg',
        thumbUrl: 'https://cdn.eventioo.com/public/static/qrmemories/6.jpg',
        webUrl: 'https://cdn.eventioo.com/public/static/qrmemories/6.jpg'
    },
    {
        id: 'demo-7',
        type: 'image/jpeg',
        thumbUrl: 'https://cdn.eventioo.com/public/static/qrmemories/7.jpg',
        webUrl: 'https://cdn.eventioo.com/public/static/qrmemories/7.jpg'
    },
    {
        id: 'demo-8',
        type: 'image/jpeg',
        thumbUrl: 'https://cdn.eventioo.com/public/static/qrmemories/8.jpg',
        webUrl: 'https://cdn.eventioo.com/public/static/qrmemories/8.jpg'
    },
    {
        id: 'demo-9',
        type: 'image/jpeg',
        thumbUrl: 'https://cdn.eventioo.com/public/static/qrmemories/9.jpg',
        webUrl: 'https://cdn.eventioo.com/public/static/qrmemories/9.jpg'
    },
    {
        id: 'demo-10',
        type: 'image/jpeg',
        thumbUrl: 'https://cdn.eventioo.com/public/static/qrmemories/10.jpg',
        webUrl: 'https://cdn.eventioo.com/public/static/qrmemories/10.jpg'
    }
]

export const useUploading = (code: string, uploadedBy: string, cancelMedia: (bucketCode: string, key: string) => Promise<void>, confirmMedia: (bucketCode: string, uploadId: string, message?: string) => Promise<void>, setSucceeded: (s: boolean) => void,
                             openModal: () => void,
                             openNotYetActivatedModal: () => void,
                             openUploadPeriodOverModal: () => void) => {
    const [filesInfo, setFilesInfo] = useState<IFileWithMeta[]>([]);
    const [uploadedAnything, setUploadedAnything] = useState(false);
    const [uploadIdToPool, setUploadIdToPool] = useState<string | null>(null);
    const [poolingStarted, setPoolingStarted] = useState<boolean>(false);

    const [allProcessed, setAllProcessed] = useState(false);
    const [alreadyProcessed, setAlreadyProcessed] = useState(0);

    const filesInfoRef = useRef<IFileWithMeta[]>(filesInfo);
    const uploadIdToPoolRef = useRef<string | null>(uploadIdToPool);

    const poolLoop = async () => {
        const currentFilesInfo = filesInfoRef.current;
        const objectKeysToPool = currentFilesInfo.filter(info => !info.meta.thumbUrl || !info.meta.webUrl).map(info => info.meta.key);

        if (code === 'demo' && objectKeysToPool.length) {
            const updated = currentFilesInfo.map(someFile => !someFile.meta.thumbUrl || !someFile.meta.webUrl  && someFile.meta?.percent < 100 ? { ...someFile, meta: { ...someFile.meta, percent: someFile.meta.percent + 5 + Math.random() % 15 }} : someFile);

            const withThumbs = updated.map(
                someFile =>
                    someFile.meta?.percent > 100 && (!someFile.meta.thumbUrl || !someFile.meta.webUrl) ?
                        { ...someFile, meta: { ...someFile.meta, percent: 100, thumbUrl: DEMO_FILES.find(demoFile => demoFile.id === someFile.meta.id)?.thumbUrl, webUrl: DEMO_FILES.find(demoFile => demoFile.id === someFile.meta.id)?.webUrl }}:
                        someFile
            )

            setFilesInfo(withThumbs);
            const alreadyProcessed = withThumbs.filter(info => info.meta.thumbUrl).length;
            setAlreadyProcessed(alreadyProcessed);
            setTimeout(poolLoop, 100);
            return;
        }

        if (objectKeysToPool.length) {
            const response = (await axios.post(`${process.env.REACT_APP_API_URL}/memories/upload-status`, {
                bucketCode: code,
                uploadId: uploadIdToPoolRef.current,
                objectKeys: objectKeysToPool
            })).data;
            const currentFilesInfo = filesInfoRef.current;
            response.forEach((mediaDto: QrMemoriesMediaDto) => {
                if (mediaDto.thumbUrl) {
                    const foundInfo = currentFilesInfo.find(info => info.meta.key === mediaDto.objectKey);
                    if (foundInfo) {
                        foundInfo.meta.thumbUrl = mediaDto.thumbUrl;
                        foundInfo.meta.webUrl = mediaDto.webUrl;
                    }
                }
            });

            const newFilesInfo = currentFilesInfo.map(info => response.find((resp: any) => resp.thumbUrl && resp.objectKey === info.meta.key) ? {
                ...info,
                meta: {
                    ...info.meta,
                    thumbUrl: response.find((resp: any) => resp.objectKey === info.meta.key).thumbUrl,
                    webUrl: response.find((resp: any) => resp.objectKey === info.meta.key).webUrl
                }
            } : info)

            setFilesInfo(newFilesInfo);
            const alreadyProcessed = newFilesInfo.filter(info => info.meta.thumbUrl).length;
            setAlreadyProcessed(alreadyProcessed);
        }
        setTimeout(poolLoop, 2000);
    }

    useEffect(() => {
        if (!code) return;
        poolLoop();
    }, [code]);

    useEffect(() => {
        filesInfoRef.current = filesInfo;
    }, [filesInfo]);
    useEffect(() => {
        uploadIdToPoolRef.current = uploadIdToPool;
    }, [uploadIdToPool]);

    useEffect(() => {
        const alreadyProcessed = filesInfo.filter(info => info.meta.thumbUrl).length;
        setAlreadyProcessed(alreadyProcessed);
        setAllProcessed(alreadyProcessed === filesInfo.length)
    }, [filesInfo]);


    const resetState = useCallback(() => {

    }, []);

    const uploadFiles = useCallback(async (filesWithMeta: IFileWithMeta[], maybeUploadId: string | null) => {

        if (code === 'demo') {
            return;
        }

        setUploadedAnything(true);
        let uploadId = null;
        try {
        setSucceeded(false);
        const response =  (await axios.post(`${process.env.REACT_APP_API_URL}/memories/prepare-upload`, { bucketCode: code, uploadedBy, uploadId: maybeUploadId, files: filesWithMeta.map(someFile => ({ id: someFile.meta.id, sizeInBytes: someFile.meta.size })) })).data;
            uploadId = response.uploadId;
            filesWithMeta.forEach((fileWithMeta, i) => {
                const uploadDetails = response.uploadDetails.find((details: any) => details.id === fileWithMeta.meta.id);
                if (!uploadDetails) {
                    throw new Error('unexpected state - no upload details found');
                }
                const formData2 = new FormData();
                fileWithMeta.meta.key = uploadDetails.key.split('/')[1];
                formData2.append('key', uploadDetails.key);
                Object.entries(uploadDetails.fields).forEach(([key, val]) => {
                    formData2.append(key, val as any);
                });
                formData2.append('x-amz-meta-type', fileWithMeta.meta.type);
                formData2.append('x-amz-meta-uploaded-by', 'Bartek');
                formData2.append('x-amz-meta-uploaded-at', uploadDetails.uploadedAt);
                formData2.append('x-amz-meta-taken-at', fileWithMeta.meta.lastModifiedDate);
                formData2.append('x-amz-meta-width', '400'); // TODO do something smart about width and height
                formData2.append('x-amz-meta-height', '400'); // TODO do something smart about width and height
                formData2.append('file', fileWithMeta.file);

                const params = { url: uploadDetails.url, body: formData2 }

                if (params === null) return
                const { url, body } = params

                if (!url) {
                    fileWithMeta.meta.status = 'error_upload_params'
                    // this.handleChangeStatus(fileWithMeta)
                    // this.forceUpdate()
                    return
                }

                const xhr = new XMLHttpRequest()
                const formData = new FormData()
                xhr.open('POST', url, true)

                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

                // update progress (can be used to show progress indicator)
                xhr.upload.addEventListener('progress', e => {
                    setFilesInfo(filesInfoRef.current.map(file => file.meta.id === fileWithMeta.meta.id ? { ...file, meta: { ...file.meta, percent: (e.loaded * 100.0) / e.total || 100 } } : file));
                });

                xhr.addEventListener('readystatechange', () => {
                    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
                    if (xhr.readyState !== 2 && xhr.readyState !== 4) return

                    if (xhr.status === 0 && fileWithMeta.meta.status !== 'aborted') {
                        fileWithMeta.meta.status = 'exception_upload'
                        // this.handleChangeStatus(fileWithMeta)
                        // this.forceUpdate()
                    }

                    if (xhr.status > 0 && xhr.status < 400) {
                        fileWithMeta.meta.percent = 100
                        if (xhr.readyState === 2) fileWithMeta.meta.status = 'headers_received'
                        if (xhr.readyState === 4) fileWithMeta.meta.status = 'done'
                        // this.handleChangeStatus(fileWithMeta)
                        // this.forceUpdate()
                    }

                    if (xhr.status >= 400 && fileWithMeta.meta.status !== 'error_upload') {
                        fileWithMeta.meta.status = 'error_upload'
                        // this.handleChangeStatus(fileWithMeta)
                        // this.forceUpdate()
                    }
                })

                formData.append('file', fileWithMeta.file)
                // if (this.props.timeout) xhr.timeout = this.props.timeout
                xhr.send(body || formData)
                fileWithMeta.xhr = xhr
                fileWithMeta.meta.status = 'uploading'
            });
        } catch (e) {
            console.error(e);
            if ((e as any).response?.data?.code === 'QrMemoTrialUploadLimitExceeded') {
                setFilesInfo(filesInfo.filter(info => !!info.meta.key));
                setTimeout(() => openModal(), 0);
            } else if ((e as any).response?.data?.code === 'QrMemoBeforeActivation') {
                setFilesInfo(filesInfo.filter(info => !!info.meta.key));
                setTimeout(() => openNotYetActivatedModal(), 0);
            } else if ((e as any).response?.data?.code === 'QrMemoUploadPeriodOver') {
                setFilesInfo(filesInfo.filter(info => !!info.meta.key));
                setTimeout(() => openUploadPeriodOverModal(), 0);
            }
        }

        // setFilesInfo(filesWithMeta);

        setUploadIdToPool(uploadId);
        // this.handleChangeStatus(fileWithMeta)
        // this.forceUpdate()
    }, [code, uploadedAnything, uploadIdToPool, allProcessed, uploadedBy, poolingStarted, filesInfo, openModal, openNotYetActivatedModal, openUploadPeriodOverModal]);


    const customOnDropAccepted = useCallback(async (files: File[], event: DropEvent) => {
        const newFilesInfo = [...filesInfo];
        const onlyNewFiles: IFileWithMeta[] = [];

        if (code === 'demo') {
            const howMany = Math.random() % 5 + 5;

            const shuffle = (array: any[]) =>  {
                let currentIndex = array.length;

                // While there remain elements to shuffle...
                while (currentIndex != 0) {

                    // Pick a remaining element...
                    let randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex--;

                    // And swap it with the current element.
                    [array[currentIndex], array[randomIndex]] = [
                        array[randomIndex], array[currentIndex]];
                }
            }

            shuffle(DEMO_FILES);

            for ( let i = 0 ; i < howMany ; i++) {
                newFilesInfo.push(
                    {
                        file: null as any,
                        meta: {
                            id: DEMO_FILES[i].id,
                            key: DEMO_FILES[i].id,
                            percent: 0,
                            type: DEMO_FILES[i].type
                        } as any
                    }
                )
            }
            setFilesInfo(newFilesInfo);
            return;

        }


        await Promise.all(
            files.map(async (file, i) => {
                const id = `${new Date().getTime()}-${i}`;
                const { name, size, type, lastModified } = file
                // const { minSizeBytes, maxSizeBytes, maxFiles, accept, getUploadParams, autoUpload, validate } = this.props

                const uploadedDate = new Date().toISOString()
                const lastModifiedDate = lastModified && new Date(lastModified).toISOString()
                const fileWithMeta = {
                    file,
                    meta: { name, size, type, lastModifiedDate, uploadedDate, percent: 0, id, status: 'preparing' },
                } as IFileWithMeta;

                newFilesInfo.push(fileWithMeta);
                onlyNewFiles.push(fileWithMeta);
                // uploadFile(fileWithMeta).then();
            })
        );

        uploadFiles(onlyNewFiles, uploadIdToPool).then();


        setFilesInfo(newFilesInfo);

    }, [uploadFiles, filesInfo, uploadIdToPool]);


    const { getRootProps, getInputProps, open } = useDropzone({
        accept: {
            'image/*': [],
            'video/*': []
        },
        multiple: true,
        onDropAccepted: customOnDropAccepted
    });

    // TODO handle cancel when no key is available yet (prepare in progress) or instead dont allow the bottom sheet
    const cancelUpload = useCallback(async (key: string) => {
        await cancelMedia(code, key);
        setFilesInfo(filesInfoRef.current.filter(info => info.meta.key !== key));
    }, [code, cancelMedia]);

    const confirmUpload = useCallback(async (message?: string) => {
        await confirmMedia(code, uploadIdToPool!, message);
        setFilesInfo([]);
        setUploadIdToPool(null);
        setAllProcessed(false);
        setPoolingStarted(false);

    }, [code, uploadIdToPool]);


    return {
        getRootProps,
        getInputProps,
        filesInfo,
        uploadedAnything,
        allProcessed,
        alreadyProcessed,
        cancelUpload,
        confirmUpload
    };
}