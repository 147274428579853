import React, {useCallback, useState} from 'react';
import {GradientButton} from "./GradientButton";

interface NoCodeScreenProps {
    inCode?: string;
    codeInvalid?: boolean;
}

export const NoCodeScreen: React.FC<NoCodeScreenProps> = ({ inCode, codeInvalid }: NoCodeScreenProps) => {

    const [code, setCode] = useState<string | null>(inCode || null);
    const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);

    const redirectAsPerCode = useCallback(() => {
        setSubmitAttempted(true);
        if (code && code.length === 12) {
            if (window.location.href.includes('code=')) {
                window.location.href = window.location.href.replace(/code=.*/, `code=${code}`);
            } else {
                window.location.href = window.location.href.includes('?') ? `${window.location.href}&code=${code}` : `${window.location.href}?code=${code}`;
            }
        }
    }, [code]);

    return <>  <div className='w-full relative bg-gradient-to-r from-orange-200 to-amber-200 bg-center bg-no-repeat h-[35vh] bg-cover -z-10'>
        <div className='h-64 absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent to-white dark:to-base-100'></div>
    </div>
        <div className={'p-4 pt-8 flex flex-col items-center justify-center'}>
        <h1 className={'text-5xl great-vibes-regular font-light'}>QR Pamiątka</h1>
            <div className='text-xs items-center text-center justify-center p-3 flex flex-row gap-1'>
                <p className='text-gray-600'>Obsługiwana przez</p> <img className='h-[30px]' src={'https://cdn.eventioo.com/public/static/logo-email.png'} />
            </div>
        {/*{ cfg?.subTitle && <h1 className={(cfg?.subTitleSize || 'text-3xl') + ' ' + (cfg?.subTitleFont || 'great-vibes-regular') +  " font-light"}>{cfg?.subTitle}</h1>}*/}
    </div>
    <div className="flex flex-col items-center justify-between min-h-full p-4 max-w-screen-sm mx-auto gap-4">

        <div className="flex flex-col justify-between w-full">
            <h2 className="text-lg font-light">Najpierw wprowadź kod</h2>
            <p className="font-light opacity-60 text-sm mb-2">Przekierujemy Cię na stronę wydarzenia</p>
            <input
                type="text"
                placeholder='Kod składa się z 12 znaków'
                className={"input input-primary w-full font-light " + (codeInvalid || submitAttempted && (!code || !(code?.length === 12)) ? 'border-error' : '')}
                value={code!}
                onChange={val => setCode(val.target.value)}
            />
            {submitAttempted && !code &&
                <div className="label">
                    <span className="label-text-alt text-error">Kod jest wymagany, jeśli nie możesz go znaleźć, zapytaj o niego organizatora wydarzenia.</span>
                </div>
            }
            {submitAttempted && code && !(code?.length === 12) &&
                <div className="label">
                    <span className="label-text-alt text-error">Prawidłowy kod ma długość 12 znaków.</span>
                </div>
            }
            {codeInvalid &&
                <div className="label">
                    <span className="label-text-alt text-error">Kod jest nieprawidłowy. Zweryfikuj jego poprawność. W razie dalszych problemów, skontaktuj się z organizatorem wydarzenia.</span>
                </div>
            }
        </div>
        <GradientButton onClick={() => redirectAsPerCode()} className={' ' + (submitAttempted && (!code || !(code?.length === 12)) ? 'opacity-60' : '')} label='Dalej' icon='fi-sr-stars' />
    </div></>;
};
