

import React, {useEffect, useState} from 'react';
import {Header2} from "./Header2";
import {BottomSheet} from "react-spring-bottom-sheet";

interface GradientProgressBarProps {
    value?: number;
    max: number;
    className?: string;
}

export const GradientProgressBar: React.FC<GradientProgressBarProps> = ({ value, max, className }: GradientProgressBarProps) => {

    if (value === undefined) {
        return (<div className={'relative h-2 rounded-full overflow-hidden  ' + (className || '')}>
            <progress className={'absolute progress ' + (className || '')} style={{ '--progress-color': '#84cc16'} as any}></progress>
        </div>);
    }

    return (
        <div className={'relative h-2 rounded-full overflow-hidden bg-gray-300 ' + (className || '')}>
            <div className="absolute top-0 bottom-0 left-0 rounded-full bg-gradient-to-r from-lime-400 to-lime-500"
                 style={{ width: 100*value/max + '%'}}>
            </div>
        </div>
    );
};


