import React, {useCallback, useEffect} from 'react';
import {LargeHeader} from "./LargeHeader";
import {GradientButton} from "./GradientButton";
import confetti from 'canvas-confetti';
import {QrMemoriesContextDto} from "../App";

interface ThankYouScreenProps {
    isMobile: boolean;
    context: QrMemoriesContextDto;
    getRootProps: any;
    getInputProps: any;
}

export const ThankYouScreen: React.FC<ThankYouScreenProps> = ({ isMobile, context, getRootProps, getInputProps }: ThankYouScreenProps) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const fireConfetti = useCallback(() => {
        var defaults = {
            spread: 360,
            ticks: 50,
            gravity: 0,
            decay: 0.94,
            startVelocity: 30,
            colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']
        };

        var scalar = 2;
        var unicorn = confetti.shapeFromText({ text: '❤️', scalar });

        var defaultsForEmoji = {
            spread: 180,
            // ticks: 60,
            // gravity: 0,
            decay: 0.96,
            startVelocity: 20,
            shapes: [unicorn],
            scalar
        };

        function shoot() {
            confetti({
                ...defaults,
                particleCount: 40,
                scalar: 1.2,
                shapes: ['star']
            });

            confetti({
                ...defaults,
                particleCount: 10,
                scalar: 0.75,
                shapes: ['circle']
            });
        }

        function shootEmoji() {
            confetti({
                ...defaultsForEmoji,
                particleCount: 30
            });

            // confetti({
            //     ...defaults,
            //     particleCount: 5,
            //     flat: true
            // });

            confetti({
                ...defaultsForEmoji,
                particleCount: 15,
                scalar: scalar / 2,
                shapes: ['circle']
            });

        }

        setTimeout(shoot, 0);
        setTimeout(shoot, 150);
    }, []);

    useEffect(() => {
        fireConfetti();
        // setTimeout(shootEmoji, 250);
    }, [fireConfetti]);

    return  <div className="flex flex-col">
        <LargeHeader isMobile={isMobile} onClick={fireConfetti} context={context} />
        <div className="flex flex-col items-center justify-between min-h-full p-4 text-center font-light  max-w-screen-sm mx-auto">
            <h2 className="text-lg">Dziękujemy!</h2>
            <p className="mb-4 font-light opacity-60">Wszystko gotowe, przygotowana przez Ciebie pamiątka została udostępniona pomyślnie.</p>
        </div>

        <div className="flex flex-col items-center justify-between min-h-full p-4 pt-0 text-center font-light max-w-screen-sm mx-auto">
            <h2 className="text-lg">Przypomniało Ci się coś jeszcze?</h2>
            {/*<p className="mb-4 font-light opacity-60">Żaden problem.</p>*/}
            <div {...getRootProps({className: 'dropzone bg-base-100 w-full  p-4 pt-2 pb-0'})}>
                {/*<input {...getInputProps()} />*/}
                <input {...getInputProps({className: 'btn btn-primary w-full'})} />
                <GradientButton label='Przygotuj kolejną pamiątkę' icon='fi-sr-camera' />

                {/*<p>Drag 'n' drop some files here, or click to select files</p>*/}


                <div className='text-xs items-center text-center justify-center p-3 flex flex-row gap-1'>
                    <p className='text-gray-600'>Obsługiwane przez</p> <img className='h-[30px]' src={'https://cdn.eventioo.com/public/static/logo-email.png'} />
                </div>
            </div>
        </div>

    </div>;
};
